import { Trans } from '@lingui/macro'

const TermsLabelComponent = () => (
  <p className="text-sm text-grey-800">
    <Trans id="trialClass.register.termsAndConditions">
      By signing up, you agree to the{' '}
      <a
        href="/terms/"
        className="hover:text-orange"
        target="_blank"
        rel="noreferrer"
      >
        Terms of Service{' '}
      </a>
      and{' '}
      <a
        href="/privacy-policy/"
        className="hover:text-orange"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy.{' '}
      </a>
      You also agree that you have parental consent. Important updates will be
      sent via email, SMS & WhatsApp, and class reminders will be sent via call.
    </Trans>
  </p>
)

export default TermsLabelComponent
